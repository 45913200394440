// Body
$body-bg: #f5f8fa;

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

$egmf-gruen: #5c7e40;
$egmf-gruen-light: #d3dfc9;
$egmf-orange: #edb315;
$egmf-violett:#685488;
$egmf-bluegrey:#3b4a60;

// Brands
$brand-primary: $egmf-gruen;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger: #bf5329;

// to replace:
$vdmi-orange1: #FFA500;
$vdmi-orange2: #F5BB55;
$vdmi-orange3: #FFDD88;
$vdmi-orange4: #FFEE99;
$vdmi-blau: #226088;
$vdmi-complement1: #6C7870;
$vdmi-complement2: #BFAFAD;
$vdmi-complement3: #F2F1EF;


// Typography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$font-family-sans-serif: "Helvetica", sans-serif;
$font-family-secondary: 'Roboto Condensed', sans-serif;
$line-height-base: 1.4;
$font-size-base: 14px !default;
$text-color: #636b6f;

// Navbar
$navbar-default-bg: #fff;

// Buttons
$btn-default-color: $text-color;

// Inputs
$input-border: lighten($text-color, 40%);
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #EEE; // doesn't work, mmai
// this works:
// $heading-bg-color: #EEE;

// Buttons:

// button definition:
//$btn-font-weight:                normal !default;
//
$btn-default-color:              #333 !default;
$btn-default-bg:                 $egmf-gruen-light !default;
$btn-default-border:             darken($egmf-gruen-light, 10%) !default;
//
$btn-primary-color: #FFFFFF !default;
$btn-primary-bg: $egmf-gruen !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;

$btn-secondary-color: #fdf68b !default;
$btn-secondary-bg: $egmf-orange !default;
$btn-secondary-border: darken($btn-secondary-bg, 5%) !default;
//
//$btn-success-color:              #fff !default;
//$btn-success-bg:                 $brand-success !default;
//$btn-success-border:             darken($btn-success-bg, 5%) !default;
//
//$btn-info-color:                 #fff !default;
//$btn-info-bg:                    $brand-info !default;
//$btn-info-border:                darken($btn-info-bg, 5%) !default;
//
//$btn-warning-color:              #fff !default;
//$btn-warning-bg:                 $brand-warning !default;
//$btn-warning-border:             darken($btn-warning-bg, 5%) !default;
//
//$btn-danger-color:               #fff !default;
//$btn-danger-bg:                  $brand-danger !default;
//$btn-danger-border:              darken($btn-danger-bg, 5%) !default;
//
//$btn-link-disabled-color:        $gray-light !default;
//
//// Allows for customizing button radius independently from global border radius
//$btn-border-radius-base:         $border-radius-base !default;
//$btn-border-radius-large:        $border-radius-large !default;
//$btn-border-radius-small:        $border-radius-small !default;

//$panel-bg:                    #fff !default;
//$panel-body-padding:          15px !default;
//$panel-heading-padding:       10px 15px !default;
//$panel-footer-padding:        $panel-heading-padding !default;
//$panel-border-radius:         $border-radius-base !default;
//
////** Border color for elements within panels
//$panel-inner-border:          #ddd !default;
//$panel-footer-bg:             #f5f5f5 !default;
//
//$panel-default-text:          $gray-dark !default;
//$panel-default-border:        #ddd !default;
//$panel-default-heading-bg:    #f5f5f5 !default;
//
//$panel-primary-text:          #fff !default;
//$panel-primary-border:        $brand-primary !default;
//$panel-primary-heading-bg:    $brand-primary !default;
//
//$panel-success-text:          $state-success-text !default;
//$panel-success-border:        $state-success-border !default;
//$panel-success-heading-bg:    $state-success-bg !default;
//
//$panel-info-text:             $state-info-text !default;
//$panel-info-border:           $state-info-border !default;
//$panel-info-heading-bg:       $state-info-bg !default;
//
//$panel-warning-text:          $state-warning-text !default;
//$panel-warning-border:        $state-warning-border !default;
//$panel-warning-heading-bg:    $state-warning-bg !default;
//
//$panel-danger-text:           $state-danger-text !default;
//$panel-danger-border:         $state-danger-border !default;
//$panel-danger-heading-bg:     $state-danger-bg !default;
