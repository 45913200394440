// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
// @import url(https://fonts.googleapis.com/css?family=Roboto+Condensed);
// Variables
@import "variables";
// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "select2";
@import "mi_mixins";



html,
body {
  height: 100%;
}

body {
  min-height:100%;
  height: auto !important;
  padding-top:75px;
  background-image: url('../images/BG_low.jpg');
  background-repeat: repeat;
  background-attachment: fixed;
}

#miwrapper {
  height:100%;
}

// make the h3 small slightly bigger:
h3, .h3 {
  small,
  .small {
    font-size: 75%;
  }
}

// set the small size to 90%:
// Ex: (12px small font / 14px base font) * 100% = about 85%
small,
.small {
  font-size:90%;
}

.bg-row-0 {
  background-color: lighten($egmf-orange, 30%);
}

.bg-row-1 {
  background-color: lighten($egmf-orange, 35%);
}

.mi-form-inline {
  display: inline;
}

// For vertical alignment:
.container-table {
  display: table;
}

.vertical-center-row {
  display: table-cell;
  vertical-align: middle;
}

.minav {
  font-family: $font-family-secondary;
}

table {
  max-width: none;
}

.mi-tabcontent {
  padding: 10px 0;
}

// Evaluations:
.evaluation {
  table {
    background-color: #FFF;
  }
  th, td {
    font-size: 11px;
    padding: 3px 2px !important;
  }
}

// Own styles:
$panel-title-color: #FFF;

.panel-default {
  .panel-heading {
    color: $panel-title-color;
    background-color: $egmf-violett;
    border-color: darken($egmf-violett, 10%);
  }
  .panel-body {
    background-color: #EEE;
  }
}

.table {
  background-color: #FFF;
}

//.btn-default {
//  background-color: $vdmi-complement3;
//  &:hover {
//    color: #636b6f;
//    background-color: #e6e5e5;
//    border-color: #adadad;
//  }
//}

//#userinfo {
//  text-decoration: underline;
//}

.mitopbar {
  padding: 15px 0;
  background-color: $vdmi-blau;
  color: $vdmi-orange1;
  .milogo, a {
    color: $vdmi-orange1;
    text-decoration: underline;
  }
  .active_country {
    color: #FFF;
    font-weight: bold;
  }
}

.mi-padding {
  padding: 10px;
}

.mipanel {
  .panel-heading a {
    text-decoration: none;
    color: $panel-title-color;
    display: block;
    width: 100%;
    &:after {
      content: attr(data-button-icon);
      float: right;
      padding-left: 1em;
    }
  }
}

.mi-col-table {
  display: table;
  .mi-bottom-element {
    height:50px;
    min-height: 50px;
    display: table-cell;
    vertical-align: bottom;
  }
}

// for unsupportive browsers:
[hidden] {
  display: none !important;
}

$spacer:5px;
// some spacing, I'm using the bootstrap 4 syntax, so it can be updated easily:
.p-1 {
  padding:$spacer !important;
}
.px-1 {
  padding-left:$spacer !important;
  padding-right:$spacer !important;
}

.my-2 {
  margin-bottom: $spacer*2;
  margin-top: $spacer*2;
}

.bg-white {
  background-color:#FFFFFF;
}

.btn-secondary {
  @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border);
}

.mi-headline {
  h1, p {
    color:#FFFFFF;
  }
  h1 {
    font-size:58px;
    font-weight:bolder;
    letter-spacing: 0.7px;
  }
  h2 {
    font-size:1.2em;
    text-transform: uppercase;
    letter-spacing: 0.4px;
  }
}

.panel-body {
  hr {
    border-top:1px solid darken($panel-bg, 15%);
  }
  .mi-buttonbar {
    padding-bottom:10px;
  }
}

.mi-datatable {
  td {
    color:darken($text-color, 10%);
  }
  td:first-child {
    width:30%;
    text-align:right;
    padding-right:10px;
    color:lighten($text-color, 15%);
    @include bp(mobile) {
      width:40%;
    }
  }
}



