@mixin mi_hide_from_dom {
	display: none !important;
	visibility: hidden;
}

@mixin mi_hide_from_screen {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
	&.focusable {
		&:active, &:focus {
			clip: auto;
			height: auto;
			margin: 0;
			overflow: visible;
			position: static;
			width: auto;
		}
	}
}

/**
 use transform-style: preserve-3d; on parent!
 */
@mixin vertical-align($position: relative) {
  position: $position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@mixin mi_clearfix {
	&:before {
		content: "";
		display: table;
	}
	&:after {
		content: "";
		display: table;
		clear: both;
	}
	zoom: 1;
}

@mixin devborder($color) {
  border:1px solid $color !important;
}

@mixin lighter {
  font-weight: 400;
  font-style: normal;
}

@mixin bold {
  font-style: normal;
  font-weight: 700;
}

/**
From twitter bootstrap:
$screen-xs:                  480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min:              $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone:               $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm:                  768px !default;
$screen-sm-min:              $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet:              $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md:                  992px !default;
$screen-md-min:              $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop:             $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg:                  1200px !default;
$screen-lg-min:              $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop:          $screen-lg-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1) !default;
$screen-sm-max:              ($screen-md-min - 1) !default;
$screen-md-max:              ($screen-lg-min - 1) !default;

*/

@mixin bp($point) {
  @if $point == desktop {
	@media (min-width: $screen-md-min) {
	  @content;
	}
  } @else if $point == tablet {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max)  {
	  @content;
	}
  } @else if $point == mobile {
	@media (max-width: $screen-sm-min) {
	  @content;
	}
  }
}


$mi-em-base:$font-size-base;
// It strips the unit of measure and returns it
@function mi-strip-unit($num) {
  @return $num / ($num * 0 + 1);
}

// Converts "px" to "em" using the ($)em-base
@function mi-convert-to-em($value) {
  $value: mi-strip-unit($value) / mi-strip-unit($mi-em-base) * 1em;
  @if ($value == 0em) {
	$value: 0;
  }
  // Turn 0em into 0
  @return $value;
}

// Working in ems is annoying. Think in pixels by using this handy function, emCalc(#)
// Just enter the number, no need to mention "px"
@function miCalcEm($values...) {
  $max: length($values); // Get the total number of parameters passed

  // If there is only 1 parameter, then return it as an integer.
  // This is done because a list can't be multiplied or divided even if it contains a single value
  @if $max == 1 {
	@return mi-convert-to-em(nth($values, 1));
  }

  $emValues: (); // This will eventually store the converted $values in a list
  @for $i from 1 through $max {
	$emValues: append($emValues, mi-convert-to-em(nth($values, $i)));
  }
  @return $emValues;
}

